import { j as s } from "./jsx-runtime-B6kdoens.js";
import { _ as c } from "./index-28E3tTLh.js";
import { forwardRef as l, createElement as m } from "react";
import { $ as u } from "./index-CZBInFmv.js";
import { c as x } from "./utils-CJ9afRe1.js";
import { c as b } from "./index-BV2fynjD.js";
const p = /* @__PURE__ */ l((t, r) => /* @__PURE__ */ m(u.label, c({}, t, {
  ref: r,
  onMouseDown: (e) => {
    var a;
    (a = t.onMouseDown) === null || a === void 0 || a.call(t, e), !e.defaultPrevented && e.detail > 1 && e.preventDefault();
  }
}))), $ = p, g = b(
  "leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      truncate: {
        true: "truncate"
      },
      required: {
        true: "truncate"
      },
      size: {
        sm: "text-xs",
        md: "text-sm",
        lg: "text-base"
      },
      variant: {
        default: "text-black-primary",
        secondary: "text-gray-neutral-70",
        destructive: "text-red-500"
      },
      fontSize: {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-base",
        lg: "text-lg",
        xl: "text-xl"
      },
      fontWeight: {
        normal: "font-normal",
        default: "font-medium",
        bold: "font-bold"
      },
      defaultVariants: {
        variant: "default",
        fontWeight: "default",
        truncate: !1,
        required: !1,
        fontSize: "sm"
      }
    }
  }
), v = l(({ className: t, variant: r, fontWeight: e, size: a, truncate: n, fontSize: f, required: d, ...o }, i) => /* @__PURE__ */ s.jsxs(
  $,
  {
    ref: i,
    className: x(g({ size: a, variant: r, fontSize: f, truncate: n, fontWeight: e, className: t })),
    ...o,
    children: [
      o.children,
      d && /* @__PURE__ */ s.jsx("span", { className: "text-blue-100", children: "*" })
    ]
  }
));
v.displayName = "Label";
export {
  v as L
};
